<template>
  <b-sidebar
    id="add-new-student-sidebar"
    :visible="isAddNewStudentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-student-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Student</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Student ID -->
          <validation-provider v-if="false" #default="validationContext" name="Student ID" rules="alpha-num">
            <b-form-group label="Student ID" label-for="student-id">
              <b-form-input
                id="student-id"
                v-model="studentData.studentId"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="4619"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- First Name -->
          <validation-provider #default="validationContext" name="First Name" rules="required">
            <b-form-group label="First Name" label-for="first-name">
              <b-form-input
                id="first-name"
                v-model="studentData.firstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- last name -->
          <validation-provider #default="validationContext" name="Last Name" rules="required">
            <b-form-group label="Last Name" label-for="last-name">
              <b-form-input
                id="last-name"
                v-model="studentData.lastName"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone Number -->
          <validation-provider #default="validationContext" name="Phone Number">
            <b-form-group label="Phone Number" label-for="phoneNumber">
              <b-form-input
                id="phoneNumber"
                v-model="studentData.phoneNumber"
                :state="getValidationState(validationContext)"
                trim
                placeholder="17035543827"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider #default="validationContext" name="Email" rules="required|email">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="studentData.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="j.doe@email.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="processing"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              :disabled="processing"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BInputGroupPrepend,
  BInputGroup,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import countries from '@/@fake-db/data/other/countries';
import store from '@/store';
import { v4 } from 'uuid';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@core/utils/utils';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewStudentSidebarActive',
    event: 'update:is-add-new-student-sidebar-active',
  },
  props: {
    isAddNewStudentSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankStudentData = {
      studentId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    };

    const toast = useToast();

    const studentData = ref(JSON.parse(JSON.stringify(blankStudentData)));
    const processing = ref(false);

    const resetstudentData = () => {
      studentData.value = JSON.parse(JSON.stringify(blankStudentData));
    };

    const { router, route } = useRouter();

    const onSubmit = () => {
      if (studentData.value) {
        studentData.value.password = v4();
      }

      store
        .dispatch('app-student/addStudent', studentData.value)
        .then((response) => {
          emit('refetch-data');
          emit('update:is-add-new-student-sidebar-active', false);

          if (route.value.query.op === 'create') {
            router.push({
              name: route.value.query.return,
              query: {
                op: 'create',
                studentId: response.data.id,
              },
            });
          }
        })
        .catch((error) => {
          processing.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetstudentData);

    return {
      studentData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      processing,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-student-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
